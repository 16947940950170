import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-child-item',
  templateUrl: './child-item.component.html',
  styleUrls: []
})
export class ChildItemComponent {
  @Input() item: any;
  constructor() { }

}
