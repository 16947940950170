import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-separator-item',
  templateUrl: './separator-item.component.html',
  styleUrls: []
})
export class SeparatorItemComponent {
  @Input() item: any;

  constructor() {
  }

}
