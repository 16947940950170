import { Component, OnInit } from '@angular/core';
import { MenuService } from './menu.service';
// @ts-ignore
import $ from 'jquery';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: []
})
export class MenuComponent implements OnInit {

  menu: object[];

  constructor(private menuService: MenuService) {
  }

  ngOnInit() {
    this.menu = this.menuService.get();
    $(() => {
      // @ts-ignore
      loadMenuActions();
    });
  }
}


