import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NavbarComponent} from './components/navbar-components/navbar.component';
import {MenuComponent} from './components/navbar-components/components/menu/menu.component';
import {SeparatorItemComponent} from './components/navbar-components/components/menu/components/separator-item/separator-item.component';
import {ParentItemComponent} from './components/navbar-components/components/menu/components/parent-item/parent-item.component';
import {ChildItemComponent} from './components/navbar-components/components/menu/components/child-item/child-item.component';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    NavbarComponent,
    MenuComponent,
    SeparatorItemComponent,
    ParentItemComponent,
    ChildItemComponent
  ],
  exports: [NavbarComponent],
  imports: [
    CommonModule,
    RouterModule
  ]
})
export class NavBarModule {
}
