import { Injectable } from '@angular/core';
import {
  Router, CanActivate
  , ActivatedRouteSnapshot, RouterStateSnapshot
} from '@angular/router';
import { Socket } from 'ngx-socket-io';

import { AuthService } from '../_services/auth.service';

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private socket: Socket
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.currentUserValue;
    if (currentUser && this.authService.isLoggedIn()) {
      this.socket.connect();
      this.socket.on('authenticate', () => {
        this.socket.emit('authenticate', currentUser.token);
      });
      return true;
    }
    this.socket.disconnect();
    this.router.navigate(['/auth/signin'], {
      queryParams: {
        returnUrl: state.url
      }
    });
    return false;
  }

  verifyPermission(permission) {
    const currentUser = this.authService.currentUserValue;
    return currentUser.permissions.indexOf(permission) >= 0;
  }
}
