import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-parent-item',
  templateUrl: './parent-item.component.html',
  styleUrls: []
})
export class ParentItemComponent {
  @Input() item: any;

  constructor() { }
}
